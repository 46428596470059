import { LayoutService } from '@acal-frontend/admin-ui';
import { CountryService, CurrencyService } from '@acal-frontend/api-services';
import {
  AuthenticationService,
  ErrorService,
  LanguageItem,
  SeoService,
  TranslationService,
} from '@acal-frontend/ui-services';
import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'expertease-admin-app-root',
  template: ` <router-outlet *ngIf="isReady"></router-outlet> `,
})
export class AppComponent implements OnInit {
  isReady = false;
  supportedLanguages: LanguageItem[] = [
    { code: 'en', label: 'English', locale: 'en-US' },
    { code: 'fr', label: 'Français', locale: 'fr-FR' },
  ];

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly countryService: CountryService,
    private readonly currencyService: CurrencyService,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
    private readonly translationService: TranslationService,
  ) {}

  ngOnInit() {
    combineLatest([
      this.countryService.getAll(
        !this.authenticationService.isAuthenticatedValue,
      ),
      this.currencyService.getAll(
        !this.authenticationService.isAuthenticatedValue,
      ),
      this.translationService
        .init(this.supportedLanguages)
        .then(() => this.errorService.init()),
    ]).subscribe(() => (this.isReady = true));

    this.seoService.registerAuthor('ExpertEase');

    this.layoutService.registerLogoName(`ExpertEase`);

    this.layoutService.registerMenu([
      {
        label: 'Home',
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/dashboard'],
          },
        ],
      },

      {
        label: 'Transactions',
        items: [
          {
            label: 'Payments',
            icon: 'pi pi-fw pi-credit-card',
            routerLink: ['/payments'],
          },

          {
            label: 'Settlements',
            icon: 'pi pi-fw pi-money-bill',
            routerLink: ['/settlements'],
          },

          {
            label: 'Customers',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/customers'],
          },

          {
            label: 'Claims',
            icon: 'pi pi-fw pi-file-edit',
            routerLink: ['/claims'],
          },
        ],
      },

      {
        label: 'Catalog',
        items: [
          {
            label: 'Expertises',
            icon: 'pi pi-fw pi-hashtag',
            routerLink: ['/expertises'],
          },

          {
            label: 'Experts',
            icon: 'pi pi-fw pi-id-card',
            routerLink: ['/experts'],
          },
        ],
      },

      {
        label: 'Configuration',
        items: [
          {
            label: 'Claim resolution types',
            icon: 'pi pi-fw pi-copy',
            routerLink: ['/claim-resolution-types'],
          },

          {
            label: 'Claim types',
            icon: 'pi pi-fw pi-file',
            routerLink: ['/claim-types'],
          },

          {
            label: 'Countries',
            icon: 'pi pi-fw pi-flag',
            routerLink: ['/countries'],
          },

          {
            label: 'Currencies',
            icon: 'pi pi-fw pi-dollar',
            routerLink: ['/currencies'],
          },

          {
            label: 'Global parameters',
            icon: 'pi pi-fw pi-spin pi-cog',
            routerLink: ['/global-params'],
          },

          {
            label: 'Hourly rates',
            icon: 'pi pi-fw pi-calculator',
            routerLink: ['/hourly-rate-configs'],
          },

          {
            label: 'Languages',
            icon: 'pi pi-fw pi-globe',
            routerLink: ['/languages'],
          },

          {
            label: 'Transfer fees',
            icon: 'pi pi-fw pi-percentage',
            routerLink: ['/transfer-fee-configs'],
          },
        ],
      },
    ]);
  }

  translate(lang: string) {
    this.translationService.translate(lang);
  }
}
