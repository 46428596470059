import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import 'reflect-metadata';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://4a074e01ea1b0a44ed6f4a16e7da6dd7@o4504696108089344.ingest.sentry.io/4506246728056832',

    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/expertease_admin_dev/,
          /^https:\/\/adm\.test\.ee\.acal\.ma/,
          /^https:\/\/adm\.uat\.ee\.acal\.ma/,
          /^https:\/\/adm\.expertea\.se/,
        ],

        routingInstrumentation: Sentry.routingInstrumentation,
      }),

      new Sentry.Replay(),
    ],

    // Performance Monitoring
    tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
